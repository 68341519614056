var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lease-tenant-detail" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _vm.isShowEditBtn
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addRoommateBtn }
                },
                [_vm._v("添加同住人\n    ")]
              )
            : _vm._e(),
          _vm.isShowEditBtn && !_vm.isPMS
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.platoonRoomBtn }
                },
                [_vm._v("修改排房信息\n    ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "tenant-cont-box" }, [
        _c(
          "div",
          { staticClass: "tenant-cont" },
          [
            _vm.signatoryData.stateStr
              ? _c("div", { staticClass: "cont-box" }, [
                  _c("div", { staticClass: "cont-left" }, [
                    _c("img", {
                      staticClass: "img",
                      attrs: { src: _vm.signatoryData.headImg }
                    }),
                    _c("p", { staticClass: "txt" }, [_vm._v("主入住人")])
                  ]),
                  _c("div", { staticClass: "cont-right" }, [
                    _c("div", { staticStyle: { "padding-bottom": "12px" } }, [
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(_vm.signatoryData.realName))
                      ]),
                      _c("span", { staticClass: "gender" }, [
                        _vm._v(_vm._s(_vm.signatoryData.genderStr))
                      ]),
                      _c("span", { staticClass: "phone" }, [
                        _vm._v(_vm._s(_vm.signatoryData.phoneNumStr))
                      ])
                    ]),
                    _vm.apartmentType == "7"
                      ? _c("p", { staticClass: "card" }, [
                          _vm._v(
                            "学号/职工号：" +
                              _vm._s(
                                _vm.signatoryData.studentCode
                                  ? _vm.signatoryData.studentCode
                                  : "--"
                              )
                          )
                        ])
                      : _vm._e(),
                    _c("p", { staticClass: "card" }, [
                      _vm._v(
                        "签约证件类型：" +
                          _vm._s(
                            _vm.signatoryData.idType
                              ? _vm.certTypeArr[_vm.signatoryData.idType]
                              : "--"
                          )
                      )
                    ]),
                    _c("p", { staticClass: "card" }, [
                      _vm._v(
                        "签约证件号码：" +
                          _vm._s(
                            _vm.signatoryData.idCardNoStr
                              ? _vm.signatoryData.idCardNoStr
                              : "--"
                          )
                      )
                    ]),
                    _c("div", { staticClass: "state" }, [
                      _vm._v(_vm._s(_vm.signatoryData.stateStr))
                    ]),
                    _vm.showDeleteTenantBtn
                      ? _c(
                          "div",
                          {
                            staticClass: "delete",
                            on: {
                              click: function($event) {
                                _vm.deleteTenant(_vm.signatoryData)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    _c("p", [
                      _vm._v("入住时间：" + _vm._s(_vm.signatoryData.startTime))
                    ]),
                    _vm.signatoryData.checkoutTime
                      ? _c("p", [
                          _vm._v(
                            "退房时间：" +
                              _vm._s(_vm.signatoryData.checkoutTime)
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              : _vm._e(),
            _vm._l(_vm.roommateDataList, function(item, index) {
              return _c("div", { key: index + 1, staticClass: "cont-box" }, [
                _c("div", { staticClass: "cont-left" }, [
                  _c("img", {
                    staticClass: "img",
                    attrs: { src: item.headImg }
                  }),
                  _c("p", { staticClass: "txt" }, [_vm._v("同住人")])
                ]),
                _c(
                  "div",
                  { staticClass: "cont-right" },
                  [
                    _c("div", { staticStyle: { "padding-bottom": "12px" } }, [
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.realName))
                      ]),
                      _c("span", { staticClass: "gender" }, [
                        _vm._v(_vm._s(item.genderStr))
                      ]),
                      _c("span", { staticClass: "phone" }, [
                        _vm._v(_vm._s(item.phoneNumStr))
                      ])
                    ]),
                    _vm.apartmentType == "7"
                      ? _c("p", { staticClass: "card" }, [
                          _vm._v(
                            "学号/职工号：" +
                              _vm._s(item.studentCode ? item.studentCode : "--")
                          )
                        ])
                      : _vm._e(),
                    _c("p", { staticClass: "card" }, [
                      _vm._v(
                        "签约证件类型：" +
                          _vm._s(
                            item.idType ? _vm.certTypeArr[item.idType] : "--"
                          )
                      )
                    ]),
                    _c("p", { staticClass: "card" }, [
                      _vm._v(
                        "签约证件号码：" +
                          _vm._s(item.idCardNoStr ? item.idCardNoStr : "--")
                      )
                    ]),
                    _c("div", { staticClass: "state" }, [
                      _vm._v(_vm._s(item.stateStr))
                    ]),
                    _vm.isShowCohabitantDelBtn
                      ? [
                          item.state == "1" &&
                          (_vm.queryData.leaseFrom == "2" ||
                            _vm.queryData.leaseFrom == "3")
                            ? _c(
                                "div",
                                {
                                  staticClass: "delete",
                                  on: {
                                    click: function($event) {
                                      _vm.deleteBtn(item)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            : _vm._e()
                        ]
                      : _vm._e(),
                    _c("p", [_vm._v("入住时间：" + _vm._s(item.startTime))]),
                    item.checkoutTime
                      ? _c("p", [
                          _vm._v("退房时间：" + _vm._s(item.checkoutTime))
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ])
            }),
            _vm._l(3 - ((_vm.roommateDataList.length + 1) % 3), function(
              item,
              index
            ) {
              return _c("div", {
                key: "k" + index + 1,
                staticClass: "cont-box-empty"
              })
            })
          ],
          2
        )
      ]),
      _vm.roommateDataList.length == 0 && !_vm.signatoryData.stateStr
        ? _c("p", { staticClass: "empty-text" }, [
            _vm._v("当前租约暂无房客信息")
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "platoon-room-dialog",
          attrs: {
            title: "添加同住人",
            visible: _vm.addRoommateVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.addRoommateVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "addRoommateForm",
              attrs: {
                model: _vm.addRoommateForm,
                rules: _vm.addRoommateRules,
                "label-width": "120px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  key: "addRoommateK1",
                  staticStyle: { width: "100%" },
                  attrs: { label: "搜索同住人", prop: "searchTenant" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        remote: "",
                        clearable: "",
                        "reserve-keyword": "",
                        placeholder: "手机号",
                        "remote-method": _vm.remoteMethod,
                        loading: _vm.loading
                      },
                      on: {
                        change: function($event) {
                          _vm.selectTrigger(_vm.addRoommateForm.searchTenant)
                        }
                      },
                      model: {
                        value: _vm.addRoommateForm.searchTenant,
                        callback: function($$v) {
                          _vm.$set(_vm.addRoommateForm, "searchTenant", $$v)
                        },
                        expression: "addRoommateForm.searchTenant"
                      }
                    },
                    _vm._l(_vm.searchTenantOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  key: "addRoommateK2",
                  attrs: { label: "姓名", prop: "realName" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled:
                        _vm.addRoommateForm.searchCertFlage ||
                        _vm.addRoommateForm.searchPhoneFlage,
                      type: "text",
                      clearable: ""
                    },
                    model: {
                      value: _vm.addRoommateForm.realName,
                      callback: function($$v) {
                        _vm.$set(_vm.addRoommateForm, "realName", $$v)
                      },
                      expression: "addRoommateForm.realName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  key: "addRoommateK10",
                  attrs: { label: "性别", prop: "gender" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled:
                          _vm.addRoommateForm.searchCertFlage ||
                          _vm.addRoommateForm.searchPhoneFlage
                      },
                      model: {
                        value: _vm.addRoommateForm.gender,
                        callback: function($$v) {
                          _vm.$set(_vm.addRoommateForm, "gender", $$v)
                        },
                        expression: "addRoommateForm.gender"
                      }
                    },
                    _vm._l(_vm.genderList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.apartmentType == "7" && _vm.addRoommateForm.idType !== "7"
                ? _c(
                    "el-form-item",
                    {
                      key: "addRoommateK11",
                      attrs: { label: "学号/职工号", prop: "studentCode" }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "text", clearable: "" },
                        model: {
                          value: _vm.addRoommateForm.studentCode,
                          callback: function($$v) {
                            _vm.$set(_vm.addRoommateForm, "studentCode", $$v)
                          },
                          expression: "addRoommateForm.studentCode"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  key: "addRoommateK3",
                  attrs: { label: "是否有证件", prop: "haveCert" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled:
                          _vm.addRoommateForm.searchCertFlage ||
                          _vm.addRoommateForm.searchPhoneFlage ||
                          _vm.addRoommateForm.searchCertChangePhoneFlag ||
                          _vm.isKey == "1" ||
                          _vm.isPMS
                      },
                      on: { change: _vm.addRoommateHCertChange },
                      model: {
                        value: _vm.addRoommateForm.haveCert,
                        callback: function($$v) {
                          _vm.$set(_vm.addRoommateForm, "haveCert", $$v)
                        },
                        expression: "addRoommateForm.haveCert"
                      }
                    },
                    _vm._l(_vm.haveCertList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.addRoommateForm.haveCert == "1"
                ? _c(
                    "el-form-item",
                    {
                      key: "addRoommateK4",
                      attrs: { label: "证件类型", prop: "idType" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            clearable: "",
                            disabled:
                              _vm.addRoommateForm.searchPhoneFlage ||
                              _vm.addRoommateForm.searchCertFlage ||
                              _vm.isKey == "1"
                          },
                          on: {
                            change: function($event) {
                              _vm.idTypeChange(_vm.addRoommateForm.idType)
                            }
                          },
                          model: {
                            value: _vm.addRoommateForm.idType,
                            callback: function($$v) {
                              _vm.$set(_vm.addRoommateForm, "idType", $$v)
                            },
                            expression: "addRoommateForm.idType"
                          }
                        },
                        _vm._l(_vm.certTypeOptions, function(item) {
                          return _c("el-option", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  item.value != "7" ||
                                  (_vm.apartmentCode &&
                                    _vm.apartmentType == "7" &&
                                    item.value == "7"),
                                expression:
                                  "item.value!='7' || (apartmentCode && apartmentType=='7' && item.value=='7')"
                              }
                            ],
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.addRoommateForm.haveCert == "1"
                ? _c(
                    "el-form-item",
                    {
                      key: "addRoommateK5",
                      attrs: {
                        label:
                          _vm.addRoommateForm.idType == "7"
                            ? "学号/职工号"
                            : "证件号码",
                        prop: "idCardNo"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          disabled: _vm.addRoommateForm.searchPhoneFlage,
                          placeholder:
                            _vm.addRoommateForm.idType == "7"
                              ? "学号/职工号"
                              : "证件号码",
                          clearable: ""
                        },
                        on: {
                          input: function($event) {
                            _vm.searchIdCardNo(_vm.addRoommateForm, "1")
                          }
                        },
                        model: {
                          value: _vm.addRoommateForm.idCardNo,
                          callback: function($$v) {
                            _vm.$set(_vm.addRoommateForm, "idCardNo", $$v)
                          },
                          expression: "addRoommateForm.idCardNo"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isUploadPic == "1"
                ? _c(
                    "el-form-item",
                    {
                      key: "addRoommateK6",
                      attrs: { label: "上传证件照片", prop: "idCardPic1" }
                    },
                    [
                      _c("div", { staticClass: "picCont" }, [
                        _c(
                          "div",
                          { staticClass: "pic-lt" },
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "avatar-uploader",
                                class: {
                                  "one-upload-disabled":
                                    _vm.addRoommateForm.idCardPic1 &&
                                    _vm.addRoommateForm.idCardPic1.length > 0
                                },
                                attrs: {
                                  accept: "image/*",
                                  "list-type": "picture-card",
                                  action:
                                    _vm.UPLOAD_URL +
                                    "/user-service/weChat/uploadPic",
                                  "file-list": _vm.addRoommateForm.idCardPic1,
                                  "on-preview": _vm.onPreviewByUploadImg,
                                  "before-upload": _vm.onBeforeUpload,
                                  "before-remove":
                                    _vm.onBeforeRemoveByUploadImg,
                                  "on-success": _vm.onSuccessByUploadImgOnly(
                                    "idCardPic1",
                                    "1"
                                  ),
                                  "on-remove": _vm.onHandleRemove(
                                    "idCardPic1",
                                    "1"
                                  ),
                                  "on-exceed": _vm.onExceed
                                }
                              },
                              [
                                _vm.addRoommateForm.idCardPic1 &&
                                _vm.addRoommateForm.idCardPic1.length == 0
                                  ? _c("i", { staticClass: "el-icon-plus" })
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.addRoommateForm.haveCert == "1"
                ? _c(
                    "el-form-item",
                    {
                      key: "addRoommateK7",
                      attrs: { label: "是否有手机号码", prop: "havePhone" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            disabled:
                              _vm.addRoommateForm.searchCertFlage ||
                              _vm.addRoommateForm.searchPhoneFlage ||
                              _vm.addRoommateForm.searchCertChangePhoneFlag ||
                              _vm.isPMS
                          },
                          on: { change: _vm.addRoommateHavePhoneChange },
                          model: {
                            value: _vm.addRoommateForm.havePhone,
                            callback: function($$v) {
                              _vm.$set(_vm.addRoommateForm, "havePhone", $$v)
                            },
                            expression: "addRoommateForm.havePhone"
                          }
                        },
                        _vm._l(_vm.havePhoneList, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.addRoommateForm.havePhone == "0" &&
              _vm.addRoommateForm.haveCert == "1"
                ? _c(
                    "el-form-item",
                    {
                      key: "addRoommateK8",
                      attrs: { label: "登录密码", prop: "passWord" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "ipnut-box" },
                        [
                          _c("el-input", {
                            staticStyle: { width: "87%!important" },
                            attrs: {
                              type: _vm.addRoommateForm.passWordType,
                              clearable: ""
                            },
                            on: {
                              focus: function($event) {
                                _vm.passWordFocus()
                              }
                            },
                            model: {
                              value: _vm.addRoommateForm.passWord,
                              callback: function($$v) {
                                _vm.$set(_vm.addRoommateForm, "passWord", $$v)
                              },
                              expression: "addRoommateForm.passWord"
                            }
                          }),
                          _c("p", { staticClass: "tip-right" }, [
                            _vm._v(
                              "房客使用证件号码（账号）和登录密码登录房客端，默认密码可修改，密码为6~12位含大小写字母和数字组成"
                            )
                          ])
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm.addRoommateForm.havePhone == "1" ||
              _vm.addRoommateForm.haveCert == "0"
                ? _c(
                    "el-form-item",
                    {
                      key: "addRoommateK9",
                      attrs: { label: "房客电话", prop: "phoneNum" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "房客电话",
                          clearable: "",
                          disabled: _vm.addRoommateForm.searchCertFlage,
                          maxlength: "11"
                        },
                        on: {
                          input: function($event) {
                            _vm.searchRealphone(_vm.addRoommateForm.phoneNum)
                          }
                        },
                        model: {
                          value: _vm.addRoommateForm.phoneNum,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.addRoommateForm,
                              "phoneNum",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addRoommateForm.phoneNum"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.addRoommateSubmit()
                        }
                      }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "platoon-room-dialog",
          attrs: {
            title: "修改排房信息",
            visible: _vm.platoonRoomVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.platoonRoomVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "dialog-box" },
            [
              _c("p", { staticClass: "tip-top" }, [
                _vm._v("备注：已实名的用户不支持修改实名身份信息")
              ]),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.rowRoomForm,
                    rules: _vm.rules,
                    "label-width": "120px"
                  }
                },
                [
                  _c("el-form-item", {
                    staticClass: "label-title",
                    attrs: { label: "房客信息" }
                  }),
                  _c(
                    "el-form-item",
                    {
                      key: "rowRoomK1",
                      attrs: { label: "姓名", prop: "realName" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          clearable: "",
                          disabled: _vm.rowRoomForm.certifiedFlage
                        },
                        model: {
                          value: _vm.rowRoomForm.realName,
                          callback: function($$v) {
                            _vm.$set(_vm.rowRoomForm, "realName", $$v)
                          },
                          expression: "rowRoomForm.realName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { key: "rowRoomK9", attrs: { label: "性别" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { disabled: _vm.rowRoomForm.certifiedFlage },
                          model: {
                            value: _vm.rowRoomForm.gender,
                            callback: function($$v) {
                              _vm.$set(_vm.rowRoomForm, "gender", $$v)
                            },
                            expression: "rowRoomForm.gender"
                          }
                        },
                        _vm._l(_vm.genderList, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.apartmentType == "7" && _vm.rowRoomForm.idType !== "7"
                    ? _c(
                        "el-form-item",
                        {
                          key: "rowRoomK10",
                          attrs: { label: "学号/职工号", prop: "studentCode" }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text", clearable: "" },
                            model: {
                              value: _vm.rowRoomForm.studentCode,
                              callback: function($$v) {
                                _vm.$set(_vm.rowRoomForm, "studentCode", $$v)
                              },
                              expression: "rowRoomForm.studentCode"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { key: "rowRoomK2", attrs: { label: "是否有证件" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.rowRoomForm.haveCert,
                            callback: function($$v) {
                              _vm.$set(_vm.rowRoomForm, "haveCert", $$v)
                            },
                            expression: "rowRoomForm.haveCert"
                          }
                        },
                        _vm._l(_vm.haveCertList, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.rowRoomForm.haveCert == "1"
                    ? _c(
                        "el-form-item",
                        {
                          key: "rowRoomK3",
                          attrs: { label: "证件类型", prop: "idType" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                clearable: "",
                                disabled: _vm.rowRoomForm.certifiedFlage
                              },
                              on: {
                                change: function($event) {
                                  _vm.idTypeChange(_vm.rowRoomForm.idType)
                                }
                              },
                              model: {
                                value: _vm.rowRoomForm.idType,
                                callback: function($$v) {
                                  _vm.$set(_vm.rowRoomForm, "idType", $$v)
                                },
                                expression: "rowRoomForm.idType"
                              }
                            },
                            _vm._l(_vm.certTypeOptions, function(item) {
                              return _c("el-option", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      item.value != "7" ||
                                      (_vm.apartmentCode &&
                                        _vm.apartmentType == "7" &&
                                        item.value == "7"),
                                    expression:
                                      "item.value!='7' || (apartmentCode && apartmentType=='7' && item.value=='7')"
                                  }
                                ],
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.rowRoomForm.haveCert == "1"
                    ? _c(
                        "el-form-item",
                        {
                          key: "rowRoomK4",
                          attrs: {
                            label:
                              _vm.rowRoomForm.idType == "7"
                                ? "学号/职工号"
                                : "证件号码",
                            prop: "idCardNo"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              clearable: "",
                              disabled: _vm.rowRoomForm.certifiedFlage
                            },
                            model: {
                              value: _vm.rowRoomForm.idCardNo,
                              callback: function($$v) {
                                _vm.$set(_vm.rowRoomForm, "idCardNo", $$v)
                              },
                              expression: "rowRoomForm.idCardNo"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isUploadPic == "1"
                    ? _c(
                        "el-form-item",
                        {
                          key: "rowRoomK5",
                          attrs: { label: "上传证件照片", prop: "idCardPic1" }
                        },
                        [
                          _c("div", { staticClass: "picCont" }, [
                            _c(
                              "div",
                              { staticClass: "pic-lt" },
                              [
                                _c(
                                  "el-upload",
                                  {
                                    staticClass: "avatar-uploader",
                                    class: {
                                      "one-upload-disabled":
                                        _vm.rowRoomForm.idCardPic1 &&
                                        _vm.rowRoomForm.idCardPic1.length > 0
                                    },
                                    attrs: {
                                      accept: "image/*",
                                      "list-type": "picture-card",
                                      action:
                                        _vm.UPLOAD_URL +
                                        "/user-service/weChat/uploadPic",
                                      "file-list": _vm.rowRoomForm.idCardPic1,
                                      "on-preview": _vm.onPreviewByUploadImg,
                                      "before-upload": _vm.onBeforeUpload,
                                      "before-remove":
                                        _vm.onBeforeRemoveByUploadImg,
                                      "on-success": _vm.onSuccessByUploadImgOnly(
                                        "idCardPic1",
                                        "2"
                                      ),
                                      "on-remove": _vm.onHandleRemove(
                                        "idCardPic1",
                                        "2"
                                      ),
                                      "on-exceed": _vm.onExceed
                                    }
                                  },
                                  [
                                    _vm.rowRoomForm.idCardPic1 &&
                                    _vm.rowRoomForm.idCardPic1.length == 0
                                      ? _c("i", { staticClass: "el-icon-plus" })
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.rowRoomForm.haveCert == "1"
                    ? _c(
                        "el-form-item",
                        {
                          key: "rowRoomK6",
                          attrs: { label: "是否有手机号码", prop: "havePhone" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", disabled: "" },
                              model: {
                                value: _vm.rowRoomForm.havePhone,
                                callback: function($$v) {
                                  _vm.$set(_vm.rowRoomForm, "havePhone", $$v)
                                },
                                expression: "rowRoomForm.havePhone"
                              }
                            },
                            _vm._l(_vm.havePhoneList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.rowRoomForm.havePhone == "0" &&
                  _vm.rowRoomForm.haveCert == "1"
                    ? _c(
                        "el-form-item",
                        {
                          key: "rowRoomK7",
                          attrs: { label: "登录密码", prop: "passWord" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "ipnut-box" },
                            [
                              _c("el-input", {
                                staticStyle: { width: "87%!important" },
                                attrs: {
                                  type: _vm.rowRoomForm.passWordType,
                                  clearable: "",
                                  disabled: _vm.rowRoomForm.certifiedFlage
                                },
                                on: {
                                  focus: function($event) {
                                    _vm.passWordFocus()
                                  }
                                },
                                model: {
                                  value: _vm.rowRoomForm.passWord,
                                  callback: function($$v) {
                                    _vm.$set(_vm.rowRoomForm, "passWord", $$v)
                                  },
                                  expression: "rowRoomForm.passWord"
                                }
                              }),
                              _c("p", { staticClass: "tip-right" }, [
                                _vm._v(
                                  "房客使用证件号码（账号）和登录密码登录房客端，默认密码可修改，密码为6~12位含大小写字母和数字组成"
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.rowRoomForm.havePhone == "1" ||
                  _vm.rowRoomForm.haveCert === "0"
                    ? _c(
                        "el-form-item",
                        {
                          key: "rowRoomK8",
                          attrs: { label: "房客电话", prop: "phoneNum" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "房客电话",
                              clearable: "",
                              maxlength: "11",
                              disabled: _vm.rowRoomForm.certifiedFlage
                            },
                            model: {
                              value: _vm.rowRoomForm.phoneNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.rowRoomForm,
                                  "phoneNum",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "rowRoomForm.phoneNum"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.cohabitantList, function(item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        [
                          _c("el-form-item", {
                            staticClass: "label-title",
                            attrs: { label: "同住人" + (index + 1) + "信息" }
                          }),
                          _c(
                            "el-form-item",
                            { attrs: { label: "姓名" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  clearable: "",
                                  disabled: item.certifiedFlage
                                },
                                model: {
                                  value: item.realName,
                                  callback: function($$v) {
                                    _vm.$set(item, "realName", $$v)
                                  },
                                  expression: "item.realName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "性别" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { disabled: item.certifiedFlage },
                                  model: {
                                    value: item.gender,
                                    callback: function($$v) {
                                      _vm.$set(item, "gender", $$v)
                                    },
                                    expression: "item.gender"
                                  }
                                },
                                _vm._l(_vm.genderList, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm.apartmentType == "7" && item.idType !== "7"
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "学号/职工号" } },
                                [
                                  _c("el-input", {
                                    attrs: { type: "text", clearable: "" },
                                    model: {
                                      value: item.studentCode,
                                      callback: function($$v) {
                                        _vm.$set(item, "studentCode", $$v)
                                      },
                                      expression: "item.studentCode"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否有证件" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: item.haveCert,
                                    callback: function($$v) {
                                      _vm.$set(item, "haveCert", $$v)
                                    },
                                    expression: "item.haveCert"
                                  }
                                },
                                _vm._l(_vm.haveCertList, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          item.haveCert == "1"
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "证件类型" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                        disabled: item.certifiedFlage
                                      },
                                      model: {
                                        value: item.idType,
                                        callback: function($$v) {
                                          _vm.$set(item, "idType", $$v)
                                        },
                                        expression: "item.idType"
                                      }
                                    },
                                    _vm._l(_vm.certTypeOptions, function(item) {
                                      return _c("el-option", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.value != "7" ||
                                              (_vm.apartmentCode &&
                                                _vm.apartmentType == "7" &&
                                                item.value == "7"),
                                            expression:
                                              "item.value!='7' || (apartmentCode && apartmentType=='7' && item.value=='7')"
                                          }
                                        ],
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          item.haveCert == "1"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      item.idType == "7"
                                        ? "学号/职工号"
                                        : "证件号码"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "text",
                                      clearable: "",
                                      disabled: item.certifiedFlage
                                    },
                                    model: {
                                      value: item.idCardNo,
                                      callback: function($$v) {
                                        _vm.$set(item, "idCardNo", $$v)
                                      },
                                      expression: "item.idCardNo"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isUploadPic == "1"
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "上传证件照片" } },
                                [
                                  _c("div", { staticClass: "picCont" }, [
                                    _c(
                                      "div",
                                      { staticClass: "pic-lt" },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "avatar-uploader",
                                            class: {
                                              "one-upload-disabled":
                                                item.idCardPic1 &&
                                                item.idCardPic1.length > 0
                                            },
                                            attrs: {
                                              accept: "image/*",
                                              "list-type": "picture-card",
                                              action:
                                                _vm.UPLOAD_URL +
                                                "/user-service/weChat/uploadPic",
                                              "file-list": item.idCardPic1,
                                              "on-preview":
                                                _vm.onPreviewByUploadImg,
                                              "before-upload":
                                                _vm.onBeforeUpload,
                                              "before-remove":
                                                _vm.onBeforeRemoveByUploadImg,
                                              "on-success": _vm.onSuccessByUploadImgOnly(
                                                "idCardPic1",
                                                "2",
                                                index
                                              ),
                                              "on-remove": _vm.onHandleRemove(
                                                "idCardPic1",
                                                "2",
                                                index
                                              ),
                                              "on-exceed": _vm.onExceed
                                            }
                                          },
                                          [
                                            item.idCardPic1 &&
                                            item.idCardPic1.length == 0
                                              ? _c("i", {
                                                  staticClass: "el-icon-plus"
                                                })
                                              : _vm._e()
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          item.haveCert == "1"
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "是否有手机号码" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        disabled: ""
                                      },
                                      model: {
                                        value: item.havePhone,
                                        callback: function($$v) {
                                          _vm.$set(item, "havePhone", $$v)
                                        },
                                        expression: "item.havePhone"
                                      }
                                    },
                                    _vm._l(_vm.havePhoneList, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          item.havePhone == "0" && item.haveCert == "1"
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "登录密码" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "ipnut-box" },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "87%!important" },
                                        attrs: {
                                          type: item.passWordType,
                                          clearable: "",
                                          disabled: item.certifiedFlage
                                        },
                                        on: {
                                          focus: function($event) {
                                            _vm.passWordFocus(index)
                                          }
                                        },
                                        model: {
                                          value: item.passWord,
                                          callback: function($$v) {
                                            _vm.$set(item, "passWord", $$v)
                                          },
                                          expression: "item.passWord"
                                        }
                                      }),
                                      _c("p", { staticClass: "tip-right" }, [
                                        _vm._v(
                                          "房客使用证件号码（账号）和登录密码登录房客端，默认密码可修改，密码为6~12位含大小写字母和数字组成"
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          item.havePhone == "1" || item.haveCert == "0"
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "房客电话" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "房客电话",
                                      clearable: "",
                                      maxlength: "11",
                                      disabled: item.certifiedFlage
                                    },
                                    model: {
                                      value: item.phoneNum,
                                      callback: function($$v) {
                                        _vm.$set(
                                          item,
                                          "phoneNum",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "item.phoneNum"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      ],
                      2
                    )
                  })
                ],
                2
              ),
              _c(
                "div",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.onSubmit("ruleForm")
                        }
                      }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }